import React from 'react';
import { Layout, SEO  } from '@componentsShared';
import { RegisterStepTwo } from '@components';
import { WrapperRegister } from './styled';

const RegisterVerification = () => {
    return (
        <Layout>
            <SEO
                title="Gana - Verificación"
                index='noindex, nofollow'
                slug="/registro/verificacion"
            />
            <WrapperRegister>
                <RegisterStepTwo />
            </WrapperRegister>
        </Layout>
    );
};

export default RegisterVerification;
