import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const WrapperRegister = styled.div`
    width: 100%;
    height: 100%;
    padding: 15px;

    ${breakpoint('md')`
        padding: 0;
    `}
`;
